<template>
	<v-row>
		<v-dialog
				v-model="dialog"
				width="600"
		>
			<template v-if="!onlyIcon" v-slot:activator="{ on, attrs }">
				<v-btn
            class="mx-4"
						color="primary"
						dark
						v-bind="attrs"
						v-on="on"
				>
					Создать ссылку
				</v-btn>
			</template>
      <template v-else v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-4"
          color="primary"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            {{mdiCurrencyUsd}}
          </v-icon>
        </v-btn>

      </template>
			<v-card>
				<v-card-text>
					<v-form>
            <v-select
              v-model="params.channel_id"
              :items="channels"
              item-value="id"
              item-text="name"
              label="Канал наш"
              placeholder="Канал наш"
              outlined
            ></v-select>
            <v-autocomplete
              v-model="params.ad_channel_id"
              :items="adChannels"
              item-value="id"
              item-text="name"
              label="Канал где покупается реклама"
              placeholder="Канал где покупается реклама"
              outlined
            ></v-autocomplete>
            <v-autocomplete
              v-model="params.ad_post_id"
              :items="adPosts"
              item-value="id"
              item-text="title"
              label="Шаблон поста"
              placeholder="Шаблон поста"
              outlined
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                >
                  <v-avatar left>
                    <v-img :src="`https://backend.telestatic.ru/upload/ad_post_images/${data.item.image}`"></v-img>
                  </v-avatar>
                  {{ data.item.title }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <img :src="`https://backend.telestatic.ru/upload/ad_post_images/${data.item.image}`">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.title"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.created_by"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-model="params.ad_welcome_id"
              :items="adWelcomes"
              item-value="id"
              item-text="title"
              label="Приветственное сообщение"
              placeholder="Приветственное сообщение"
              outlined
            ></v-autocomplete>
            <v-menu
              ref="menu"
              v-model="calendarMenu"
              :close-on-content-click="false"

              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="params.planned_at"
                  label="Запланированная дата выхода"
                  :prepend-icon="mdiCalendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="params.planned_at"
                no-title
                scrollable
                locale="ru-RU"
                @input="calendarMenu = false"
              >
              </v-date-picker>
            </v-menu>
            <v-btn class="ma-2" color="info" @click="params.planned_at = moment(new Date().getTime() + (24 * 60 * 60 * 1000)).format('YYYY-MM-DD')">Завтра</v-btn>
            <v-btn class="ma-2" color="info" @click="params.planned_at = moment(new Date().getTime() + (24 * 60 * 60 * 1000 * 2)).format('YYYY-MM-DD')">Послезавтра</v-btn>
            <v-btn class="ma-2" color="info" @click="params.planned_at = moment(new Date().getTime() + (24 * 60 * 60 * 1000 * 3)).format('YYYY-MM-DD')">+3 дня</v-btn>

            <v-checkbox
              v-model="params.manual_join"
              label="Вход по одобрению"
              class="mt-0"
            >
              <template v-slot:append>
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      {{mdiInformationOutline}}
                    </v-icon>
                  </template>
                  Если галочка есть - то бот будет одобрять заявки на вступление и подсчитывать, сколько подписоты пришло. Если нет - то вход будет сразу, без заявки.
                </v-tooltip>
              </template>
            </v-checkbox>
            <v-text-field
              v-model="params.cost"
              label="Стоимость поста"
              placeholder="Стоимость поста"
              outlined
            >
            </v-text-field>
          </v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" @click="create()">Создать</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import {mdiInformationOutline, mdiCalendar, mdiCurrencyUsd} from '@mdi/js'
import QueryString from 'qs'
import moment from 'moment'
import 'vue2-datepicker/index.css'
import colors from 'vuetify/lib/util/colors'
import httpClient from '../../services/http.service'

export default {
  name: 'CreateLink',
  // components: { DatePicker},
  props:{
    onlyIcon:{
      type: Boolean,
      default: false,
    },
    channel:{
      type: Object,
    },
    adChannelId:{
      type: Number,
      default: 0
    },
    adPostId:{
      type: Number,
      default: 0,
    },
    showDialog:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      mdiCurrencyUsd, mdiInformationOutline, mdiCalendar, colors, moment,
      calendarMenu: false,
      params:{channel_id: 0, planned_at: null, manual_join: true},
      channelsSelected: [],
      dialog: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: true,
      },
    }
  },
  computed:{
    channels(){
      return this.$store.state.channels
    },
    adPosts(){
      return this.$store.state.adPosts
    },
    adChannels(){
      return this.$store.state.adChannels
    },
    adWelcomes(){
      return this.$store.state.adWelcomes
    }
  },
  watch:{
    showDialog(val){
      if (val){
        this.dialog = true
      }
    }
  },
  created() {
    this.$store.dispatch('getChannels')
    this.$store.dispatch('getAdChannels')
    this.$store.dispatch('getAdPosts')
    this.$store.dispatch('getAdWelcomes')
  },
  mounted() {
    if (this.adChannelId > 0) {this.params.ad_channel_id = this.adChannelId; console.log('есть ID рекламного канала! есть подставить!', this.adChannelId, JSON.parse(JSON.stringify(this.params)))}
    if (this.adPostId > 0) {this.params.ad_post_id = this.adPostId}
  },
  methods:{
    create(){
      // this.params.ad_channel_id = this.channel.id
      httpClient.post('ad-publications/create', QueryString.stringify(this.params)).then(({data})=>{
        if (data.success){
          this.dialog = false
          this.$emit('reload-links')
        }
      })
    },
    notYesterday(date){
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    }
  }
}
</script>

<style scoped>

</style>
